define("discourse/plugins/discourse-lti/discourse-lti/api-initializers/hide-lti-login-button", ["exports", "discourse/lib/api", "discourse/models/login-method", "discourse-common/utils/decorators"], function (_exports, _api, _loginMethod, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    // LTI login must be initiated by the IdP
    // Hide the LTI login button on the client side:
    api.modifyClass("component:login-buttons", dt7948.p({
      pluginId: "discourse-lti",
      buttons() {
        return this._super().filter(m => m.name !== "lti");
      }
    }, [["method", "buttons", [_decorators.default]]]));

    // Connection is possible, but cannot be initiated
    // by Discourse. It must be initiated by the IdP.
    // Hide the button to avoid confusion:
    const lti = (0, _loginMethod.findAll)().find(p => p.name === "lti");
    if (lti) {
      lti.can_connect = false;
    }
  });
});